
<template>
  <b-modal
      id="editIndustryModal"
      ref="modal"
      title="Edit Industry"
      @show="resetModal"
      @hidden="resetModal"
      hide-footer
    >
      <form ref="form" @submit.prevent="update_industry">
          <b-alert show variant="danger" v-if="error" class="text-center">{{msg}}</b-alert>
        <b-form-group
          label="Title"
          label-for="name-input"
          invalid-feedback="Title is required"
        >
          <b-form-input
            id="name-input"
            v-model="industry_title"
            :class="{ 'is-invalid': isValid && $v.industry_title.$error }"
          ></b-form-input>
        </b-form-group>
      <b-button variant="primary" v-on:click="update_industry">Update</b-button>
      </form>
    </b-modal>
</template>

<script>
import { industryMethods } from "@/state/helpers";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      msg:'',
      error: false,
      isValid: false,
      industry_title : '',
      industry_id:0,
    };
  },

  props: {
    selected_title: {
      type: String,
    },
    selected_id:{
      type: Number,
    }
  },
  validations: {
    industry_title: {
      required
    },
  },
  methods: {
    ...industryMethods,
    // reset all fields of modal on click
    resetModal(){
    this.industry_title=this.selected_title;
    this.industry_id=this.selected_id;
    this.msg='';
    this.error=false;
    this.isValid = false;
    },
    // hit api to update industry
    update_industry() {
      this.msg='';
      this.isValid = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }      
     this.updateIndustry({id:this.industry_id,title:this.industry_title}).then(res => {
       this.$refs['modal'].hide();
        if (res.status == 200) {
          this.msg = res.message ? res.message : "Industry updated successfully"
          this.$swal({
            icon : 'success',
            text: this.msg,
            toast:true,
            showCloseButton: false,
            showConfirmButton: false,
            timer: 3000
          }).then(
          this.getAllIndustries(),
          );
        }
      }).catch(error => {
        this.error = true;
        if (error.data.status == 422) {
          this.msg = error.data.errors.title[0] ? error.data.errors.title[0] : "Something went wrong!!!"
        }
        else{
        this.$swal({
            icon : 'error',
            toast : true,
            text: "Failed to save! Try again",
          showCloseButton: false,
          showConfirmButton: false,
          timer: 3000
        });
        }
      })
    },
  },
  mounted(){

  }
};
</script>
