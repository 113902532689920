
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <span class="card-title h4">All Industries</span>
              <button class="float-right btn btn-primary" v-b-modal.addIndustryModal @click="addIndustryModal">Add</button>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
<!--                <div id="tickets-table_length" class="dataTables_length">-->
<!--                  <label class="d-inline-flex align-items-center">-->
<!--                    Show-->
<!--                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entries-->
<!--                  </label>-->
<!--                </div>-->
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="industry"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
              <template v-slot:cell(created_at)="{item}">
                  <span>{{ new Date(item.created_at).toLocaleString() }}</span>
              </template>
                <!-- <template v-slot:cell(created_at)="item">
                  <span>{{ format_date(item.created_at) }}</span>
              </template> -->
              <template v-slot:cell(actions)="industry">
                  <button class="btn btn-primary btn-sm" v-b-modal.editIndustryModal @click="editIndustry(industry)">Edit</button>&nbsp;
                  <button class="btn btn-danger btn-sm" @click="delete_industry(industry)">Delete</button>&nbsp;
              </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddIndustriesModal v-if="add_form" :modal_placeholder="'Enter Industry Title'"/>
    <EditIndustriesModal v-if="edit_form" :selected_id="getId" :selected_title="getTitle"/>
    <!-- <EditIndustriesModal v-if="edit_form" :selected_id="getId" :selected_title="getTitle" @modifiedData="update_industry"/> -->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import AddIndustriesModal from "./add_settings/industries";
import EditIndustriesModal from "./edit_settings/industries";
import { industryMethods } from "@/state/helpers";
import appConfig from "@/app.config";
import moment from 'moment';

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Industries",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { AddIndustriesModal, EditIndustriesModal, Layout, PageHeader },
  data() {
    return {
      title: "Industries",
      items: [
        {
          text: "Settings",
          to: "/industries"
        },
        {
          text: "Industries",
          href: "/"
        }
      ],
      msg:"",
      add_form:false,
      edit_form:false,
      getId:0,
      getTitle:"",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      fields: 
      [
        {key: "title", label: 'Title', sortable: true},
        {key: "created_at", label: 'Created At', sortable: true, dataType: "Date"},
        {key: "actions",label: 'Actions',},
      ]
    };
  },
  computed: {
    rows() {
      return this.$store.state.industry.industries.length;
    },
    industry(){
        return this.$store.state.industry.industries
    }
  },
  methods: {
    ...industryMethods,

    // get all industries
    getIndustries(){
      this.getAllIndustries().then().catch(error=>{
        this.unauthorizedError(error);
      });
    },

    delete_industry(industry){
      // deleteIndustry
        // let length=this.$store.state.industry.industries.length-1;
        this.$swal({
        icon: 'warning',
        text: 'Are you sure to delete',
        showCancelButton: true,
          toast: true,
        confirmButtonColor: 'green',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        }).then(result => {
          if (result.value) {
            this.msg='';
            this.deleteIndustry({id:industry.item.id}).then(res => {
            if (res.status === 200) {
              this.msg = res.message ? res.message : "Industry deleted asuccessfully"
              this.$swal({
                icon : 'success',
                text: this.msg,
                toast : true,
                showCloseButton: false,
                  showConfirmButton: false,
                  timer: 3000
              }).then(
              // this.industry.splice(length-industry.index,1),
              // this.totalRows--,
              // this.$delete(this.industry,length-industry.index),
                this.getAllIndustries(),
              );
            }
            }).catch(error => {
              this.msg = error ? error : "Something went wrong!!!"
              this.$swal({
                icon : 'error',
                text: this.msg,
                toast : true,
                showCloseButton: false,
                  showConfirmButton: false,
                  timer: 3000
                });
              this.unauthorizedError(error)
              }).then(
                this.getAllIndustries(),
              );
            }
          });
    },
    unauthorizedError(err) {
      if (err.data.status === 401) {
        return this.$router.push('/logout');
      }
    },

    // set the date format
    format_date(value){
          return moment(value).format("DD MM YYYY")
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    // onclick to show data model of edit industry
    editIndustry(industry) {
      this.getId = industry.item.id;
      this.getTitle = industry.item.title;
      this.edit_form=true;
    },

    // onclick to show data model of add industry
    addIndustryModal(){
      this.add_form=true;
    }
  },
  mounted() {
  this.getIndustries();
  },
  created() {
  },
};
</script>
