
<template>
  <b-modal
      id="addIndustryModal"
      ref="modal"
      title="Add Industry"
      @show="resetModal"
      @hidden="resetModal"
      hide-footer
    >        
      <form ref="form" @submit.prevent="add_industry">
          <b-alert show variant="danger" v-if="error" class="text-center">{{msg}}</b-alert>
        <b-form-group
          label="Title"
          label-for="name-input"
          invalid-feedback="Title is required"
        >
          <b-form-input
            id="name-input"
            v-model="industry_title"
            :placeholder="modal_placeholder"
            :class="{ 'is-invalid': isValid && $v.industry_title.$error }"
            aria-describedby="name-input-feedback"
          ></b-form-input>
        </b-form-group>
      <b-button variant="primary" v-on:click="add_industry">Submit</b-button>
      </form>
    </b-modal>
</template>

<script>
import { industryMethods } from "@/state/helpers";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      msg : "",
      error: false,
      isValid: false,
      industry_title : '',
    };
  },
  props: {
    modal_placeholder: {
      type: String,
    } 
  },
  validations: {
    industry_title: {
      required
    },
  },
  methods: {
    ...industryMethods,
    resetModal(){
      this.industry_title='';
      this.msg='';
      this.error=false;
      this.isValid = false;
    },
    add_industry() {
      this.msg='';
      this.isValid = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      this.addIndustry({title:this.industry_title}).then(res => {
        this.$refs['modal'].hide();
        if (res.status == 200) {
        this.msg ="Industry added successfully"
        this.$swal({
            icon : 'success',
            toast : true,
            text: this.msg,
          showCloseButton: false,
          showConfirmButton: false,
          timer: 3000
        })
        this.getAllIndustries()
        }
      }).catch(error => {
        this.error = true;
        if (error.data.status == 422) {
          this.msg = error.data.errors.title[0] ? error.data.errors.title[0] : "Something went wrong!!!"
        }
        else{
        this.$swal({
            icon : 'error',
            toast : true,
            text: "Failed to save! Try again",
          showCloseButton: false,
          showConfirmButton: false,
          timer: 3000
        });
        }
      })
    },
  }
};
</script>
